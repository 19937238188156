// METHODS

function scrollOnVisibilityAnimate(callback, el, threshold = 0.5) {
  let observer = new IntersectionObserver(
    (entry) => {
      if (entry?.[0]?.isIntersecting) {
        callback();
        observer.unobserve(el);
      }
    },
    {
      rootMargin: "0px",
      threshold,
    }
  );

  observer.observe(el);
}

function scrollCallbackForBG(AnimationInstance, elClass, divideRatio = 800) {
  let el = $(elClass);
  return () => {
    let scrollOffset = window.pageYOffset || window.scrollTop;
    let elOffset = el.offset().top - window.innerHeight;

    let readyScroll = scrollOffset - elOffset;
    if (readyScroll > 0) {
      let scrollDivided = readyScroll / (divideRatio + window.innerHeight);
      AnimationInstance.seek(AnimationInstance.duration * scrollDivided);
    }
  };
}

// INIT CALLBACK SCOPE

$(() => {
  new LazyLoad({
    use_native: true,
  });

  setContactListener();

  scrollOnVisibilityAnimate(
    () => {
      anime({
        targets: ".case-study",
        opacity: ["0%", "100%"],
        translateY: ["40px", "0"],
        easing: "easeInOutQuad",
        duration: 800,
        delay: anime.stagger(400),
      });
    },
    document.querySelector(".section-case-studies__container"),
    0.4
  );

  scrollOnVisibilityAnimate(
    () => {
      anime({
        targets: ".section-solutions",
        opacity: ["0%", "100%"],
        easing: "easeInOutQuad",
        duration: 800,
      });

      anime({
        targets: ".solution__img",
        opacity: ["0%", "100%"],
        translateY: ["-10px", "0"],
        easing: "easeInOutQuad",
        duration: 800,
        delay: anime.stagger(500, { start: 300 }),
      });
    },
    document.querySelector(".section-solutions__container"),
    0.3
  );

  scrollOnVisibilityAnimate(
    () => {
      anime({
        targets: ".service-info span",
        opacity: ["0%", "100%"],
        translateX: ["-20px", "0"],
        easing: "easeInOutQuad",
        duration: 500,
        delay: anime.stagger(300),
      });
    },
    document.querySelector(".services"),
    0.5
  );

  // HERO animations
  anime({
    targets: ".onload-animate-in",
    opacity: ["0%", "100%"],
    translateY: ["-10px", "0"],
    easing: "easeInOutQuad",
    duration: 600,
    delay: anime.stagger(200, { start: 100 }),
  });
  anime({
    targets: ".hero-animation",
    opacity: ["0%", "100%"],
    translateY: ["-10px", "0"],
    easing: "easeInOutQuad",
    duration: 800,
    delay: 300,
  });

  // BG animations

  let scrollCallbackBlueDotLeft = scrollCallbackForBG(
    anime({
      targets: ".bg-graphic--top-left",
      translateY: -90,
      translateX: -45,
      autoplay: false,
      easing: "easeInOutQuad",
    }),
    ".bg-graphic--top-left",
    150 // height of the animation element
  );

  let scrollCallbackBlueDotRight = scrollCallbackForBG(
    anime({
      targets: ".bg-graphic--top-right",
      translateY: -100,
      translateX: 50,
      autoplay: false,
      easing: "easeInOutQuad",
    }),
    ".bg-graphic--top-right",
    150 // height of the animation element
  );

  let scrollCallbackBlueDotMiddle = scrollCallbackForBG(
    anime({
      targets: ".bg-graphic--team img",
      translateY: -160,
      autoplay: false,
      easing: "easeInOutQuad",
    }),
    ".bg-graphic--team img",
    750
  );

  let scrollCallbackResourcesNet = scrollCallbackForBG(
    anime({
      targets: ".bg-graphic--resources img",
      translateY: 120,
      autoplay: false,
      easing: "linear",
    }),
    ".section-resources",
    window.innerHeight
  );
  let scrollCallbackFooterNet = scrollCallbackForBG(
    anime({
      targets: ".bg-graphic--footer img",
      translateY: 120,
      autoplay: false,
      easing: "linear",
    }),
    ".navigation-footer",
    window.innerHeight
  );

  // ====================
  // HERO ANIMATION BELOW
  // ====================

  let commonProps = {
    easing: "easeInOutSine",
    autoplay: false,
  };

  let OrangeBarMove = anime({
    targets: ".svg-logotype .orange-bar",
    translateY: 30,
    translateX: 50,
    delay: 300,
    elasticity: 200,
    ...commonProps,
  });

  let LeftLetterSideMove = anime({
    targets: ".svg-logotype .letter-part-left",
    translateY: 30,
    translateX: -50,
    delay: 200,
    elasticity: 200,
    ...commonProps,
  });

  let LeftLetterSide2Move = anime({
    targets: ".svg-logotype .letter-part-left-2",
    translateY: 60,
    translateX: -100,
    elasticity: 200,
    ...commonProps,
  });

  let LetterTopMove = anime({
    targets: ".svg-logotype .letter-roof",
    translateY: -40,
    elasticity: 200,
    ...commonProps,
  });

  let LetterFrontMove = anime({
    targets: ".svg-logotype .letter-part-front",
    translateY: 60,
    translateX: 100,
    elasticity: 200,
    ...commonProps,
  });

  let LetterFront2Move = anime({
    targets: ".svg-logotype .letter-part-3",
    translateY: 60,
    translateX: 100,
    elasticity: 200,
    ...commonProps,
  });

  // let mediaQMobileMatch = window.matchMedia("(max-width: 780px)")?.matches;
  let HAOpacity = anime({
    targets: ".svg-logotype",
    opacity: 0,
    ...commonProps,
  });

  function scrollCallbackForHero() {
    let scrollOffset = window.pageYOffset || window.scrollTop;
    let scrollDivided = scrollOffset / 500;

    LetterFrontMove.seek(LetterFrontMove.duration * scrollDivided);
    LetterFront2Move.seek(LetterFront2Move.duration * scrollDivided);
    LeftLetterSideMove.seek(LeftLetterSideMove.duration * scrollDivided);
    LetterTopMove.seek(LetterTopMove.duration * scrollDivided);
    LeftLetterSide2Move.seek(LeftLetterSide2Move.duration * scrollDivided);

    OrangeBarMove.seek(OrangeBarMove.duration * scrollDivided);
    HAOpacity.seek(HAOpacity.duration * scrollDivided);
  }

  scrollCallbackForHero();

  scrollCallbackBlueDotLeft();
  scrollCallbackBlueDotRight();
  scrollCallbackBlueDotMiddle();
  scrollCallbackResourcesNet();
  scrollCallbackFooterNet();

  $(window).on(
    "scroll",
    requestAnimationFrame.bind(null, () => {
      scrollCallbackForHero();

      scrollCallbackBlueDotLeft();
      scrollCallbackBlueDotRight();
      scrollCallbackBlueDotMiddle();
      scrollCallbackResourcesNet();
      scrollCallbackFooterNet();
    })
  );
});
