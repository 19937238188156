$(() => {
  let applyClassActiveScroll = () => {
    // apply class to detect scrolling
    let headerEl = $("header");
    let top =
      (window.pageYOffset || document.documentElement.scrollTop) -
      (document.documentElement.clientTop || 0);
    if (top === 0) headerEl.removeClass("active-scroll");
    else headerEl.addClass("active-scroll");
  };

  applyClassActiveScroll();

  // JUMPY HEADER

  let scrollUp = "scroll-up";
  let scrollDown = "scroll-down";
  let lastScroll = 0;
  let applyClassScroll = () => {
    applyClassActiveScroll();

    let headerEl = $("header");
    // hide show header on scroll
    let currentScroll = window.pageYOffset;
    if (currentScroll <= 0) {
      headerEl.removeClass(scrollUp);
      return;
    }

    if (currentScroll > lastScroll && !headerEl.hasClass(scrollDown)) {
      // down
      headerEl.removeClass(scrollUp);
      headerEl.addClass(scrollDown);
    } else if (currentScroll < lastScroll && headerEl.hasClass(scrollDown)) {
      // up
      headerEl.removeClass(scrollDown);
      headerEl.addClass(scrollUp);
    }
    lastScroll = currentScroll;
  };

  // HEADER SPECIFIC DYNAMIC HEIGHT
  function applyHeaderDynamicHeight() {
    let headerHeight = $(".header").height();
    $(".header__nav").css(
      "height",
      (window.innerHeight - headerHeight).toString() + "px"
    );
  }

  function applyBodyNoScroll() {
    // Make sure to do it only on mobile ( default breakpoint is 650 )
    if (window.innerWidth < 650) $("body").toggleClass("no-scroll");
  }

  // MOBILE NAVIGATION TOGGLE
  function setHamburgerListener() {
    $(".hamburger").on("click", () => {
      $(".header").toggleClass("is-active");

      applyHeaderDynamicHeight();
      applyBodyNoScroll();
    });
  }

  function buildDynamicMenu(response) {
    let html = `
      <div class='multi-item-menu__item header__nav-item-dynamic-container nav-{{key}}'>
        
        <a href="{{url}}{{^url}}javascript:void(0){{/url}}" class="header__nav-item multi-item-menu__item nav-{{key}} header__nav-lvl-2">
          <span class='header__nav-item-span'>{{title}}</span>
        </a>

        <div class="multi-item-menu multi-item-menu--dynamic">
        {{#menu}}
          <a href="{{url}}" class="header__nav-item multi-item-menu__item nav-{{key}} header__nav-lvl-3">
            <span class='header__nav-item-span'>{{title}}</span>
          </a>
        {{/menu}}
        </div>
      </div>
      `;

    let dynamicMenuEl = $(".dynamic-menu-container");
    response.items.map((item) => {
      let rendered = Mustache.render(html, item);
      dynamicMenuEl.append(rendered);
    });
  }

  function setDynamicMenuListeners(itemsSize) {
    if (itemsSize < 1) {
      $(".header__nav-item-mobile-expand").each((value) => {
        $(value).toggleClass("hidden");
      });
      return;
    }

    $(".header__nav-item-mobile-expand").each((value, index) => {
      $(value).on("click", () => {
        let dataKey = $(value).data("section");
        $(".header__nav-item-dynamic-container.nav-" + dataKey).toggleClass(
          "active-menu"
        );
      });
    });
  }

  // APPLY ACTIVE CLASS ON ACTIVE NAVIGATION ITEM
  function applyActivePageClass() {
    // Generic items
    let navItems = [
      "product",
      "strategy",
      "expertise",
      "portfolio",
      "about",
      "news",
      "clients",
      "events",
      "innovation",
      "reports",
      "contact",
      "customer-experience",
      "service-design",
    ];

    // Expertise items
    let pages = ["product", "strategy", "innovation"];

    let pathArray = window.location.pathname.substring(1).split("/");
    let rootPathItem = pathArray[0];

    // Clear the classes
    $(".header__nav-item-span").removeClass("active");
    $(".header__nav-item").removeClass("active");

    if (rootPathItem === "services") {
      $(".nav-services").addClass("active");
      let sectionKey = pathArray[1];
      let itemKey = pathArray[2];

      if (sectionKey) $(".nav-" + sectionKey).addClass("active");
      if (itemKey) $(".nav-" + itemKey).addClass("active");
    }

    if (rootPathItem === "clients") {
      $(".nav-" + window.location.hash?.replace("#", "")).addClass("active");
    }

    if (navItems.indexOf(rootPathItem) >= 0) {
      if (pages.indexOf(rootPathItem) >= 0) {
        $(".multi-item-menu__item-label").addClass("active");
      }

      $(".nav-" + rootPathItem).addClass("active");
    }
  }

  function setHashRouteListeners() {
    $(".hash-route").on("click", () => {
      let pathArray = window.location.pathname.substring(1).split("/");
      let rootPathItem = pathArray[0];

      if (rootPathItem === "clients") {
        // For hash to update, yeah, i know, this is ridiculous hack
        setTimeout(() => {
          window.location.reload();
        }, 100);
      }
    });
  }

  function initDynamicNav() {
    fetch("/cms/services-nav")
      .then((r) => r.json())
      .then((response) => {
        buildDynamicMenu(response);
        return response;
      })
      .then((response) => {
        setDynamicMenuListeners(response.items.length);
        applyActivePageClass();
      });
  }

  let onHeaderResize = debounce(applyHeaderDynamicHeight, 150);

  $(window).on("resize", onHeaderResize);
  $(window).on("scroll", requestAnimationFrame.bind(null, applyClassScroll));

  // INIT
  setHashRouteListeners();
  setHamburgerListener();
  applyActivePageClass();
  initDynamicNav();
});
