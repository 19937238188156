function setContactListenerHome() {
  let form = $("#message_form");
  let formBtn = $("#message_form .btn");

  formBtn.on("click", (e) => {
    e.preventDefault();

    if (grecaptcha.getResponse(formCaptcha).length === 0) {
      alert("Please complete reCapture to proceed");
      return false;
    }

    if (ga) {
      ga("send", "event", "contact", "submit");
    }

    // Get values
    let values = {
      FORM_ID: "92ddcab8-b64c-4f92-94c7-b2862112dacf",
      COMPLETE_URL: "http://ph1.ca/contact",
      FIRST_NAME: sanitizeText(form[0].fname.value),
      LAST_NAME: sanitizeText(form[0].lname.value),
      ORGANISATION_NAME: sanitizeText(form[0].company.value),
      EMAIL: form[0].email.value,
      QUESTION: sanitizeText(form[0].question.value),
      "g-recaptcha-response": grecaptcha.getResponse(formCaptcha),
    };

    // Reset fields
    form[0].reset();
    grecaptcha.reset(formCaptcha);

    // Send an email to the host
    fetch("/api/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((r) => {
        return r.json();
      })
      .then((r) => {
        if (r.status === "Success") {
          alert(
            "Message successfully submitted, we will get back to you shortly"
          );
        } else {
          alert("There was a problem submitting a message, please try again");
        }
      });
  });
}

function checkReCaptchaDefined() {
  if (
    window.grecaptcha &&
    window.grecaptcha?.render &&
    $("#form-captcha").length
  ) {
    formCaptcha = grecaptcha.render("form-captcha", {
      sitekey: "6Ld6zhsUAAAAAMd0hvDpZtjvt1q8hxrzt2VOOHSC",
      theme: "dark",
    });
  } else {
    window.setTimeout(checkReCaptchaDefined, 1000);
  }
}

function setContactListener() {
  checkReCaptchaDefined();
  let form = $("#message_form");
  form.on("submit", (e) => {
    e.preventDefault();

    if (grecaptcha.getResponse(formCaptcha).length === 0) {
      alert("Please complete reCapture to proceed");
      return false;
    }

    if (ga) {
      ga("send", "event", "contact", "submit");
    }

    // Get values
    let values = {
      FORM_ID: "92ddcab8-b64c-4f92-94c7-b2862112dacf",
      COMPLETE_URL: "http://ph1.ca/contact",
      FIRST_NAME: sanitizeText(form[0].fname.value),
      LAST_NAME: sanitizeText(form[0].lname.value),
      ORGANISATION_NAME: sanitizeText(form[0].company.value),
      EMAIL: form[0].email.value,
      QUESTION: sanitizeText(form[0].question.value),
      "g-recaptcha-response": grecaptcha.getResponse(formCaptcha),
    };

    console.log(values);

    // Reset fields
    form[0].reset();
    grecaptcha.reset(formCaptcha);

    // Send an email to the host
    fetch("/api/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((r) => {
        return r.json();
      })
      .then((r) => {
        if (r.status === "Success") {
          alert(
            "Message successfully submitted, we will get back to you shortly"
          );
        } else {
          alert("There was a problem submitting a message, please try again");
        }
      });
  });
}
